// Create custom theme
// https://storybook.js.org/docs/configurations/theming/

// @ts-ignore
import { create } from '@storybook/theming/create'

const storybookTheme = create({
  base: 'light',
  brandTitle: 'KYC UI',
  brandUrl: 'https://github.com/kycltd/kyc_strorybook'
})

export default storybookTheme
